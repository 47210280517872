import axios from 'axios';
import AuthService from './auth-header';
// /*dev*/const API_URL = 'http://192.168.1.81:5000/api/';
// /*prod*/ // const API_URL = 'https://ats.sattvahuman.in/api/';


let API_URL = ''
if (process.env.NODE_ENV === "production") {
    API_URL = 'https://ats.sattvahuman.in/api/';
} else {
    API_URL = 'http://192.168.1.81:5000/api/';
}
class JobService {
  async addJob(data) {
    try {
      const res = await axios.post(API_URL + 'addJob/', data, 
      { 
        headers: AuthService.authHeaderForm()
      });
      //console.log(res.data) 
      return res.data 
    }
    catch (err) {
      console.log(err.response);
      return err.response
    }
  }
  //Not in use
  async getAllJobs(id) {
    try {
      if (id !== 'null') {
        const res = await axios.get(API_URL + 'jobs?job_id=' + id,
        { 
          headers: AuthService.authHeader()
        });
        //console.log(res)
        return res.data
      } else {
        const res = await axios.get(API_URL + 'jobs',
        { 
          headers: AuthService.authHeader()
        });
        return res.data
      }
    }
    catch (err) {
      console.log(err.response);
      return err.response
    }
    
  }
  //Not in use
  async getJob(id) {
    try {
      
        const res = await axios.get(API_URL + 'job/' + id,
        { 
          headers: AuthService.authHeader()
        });
        //console.log(res)
        return res.data
      
    }
    catch (err) {
      console.log(err.response);
      return err.response
    }
    
  }
  async getJobData(id) {
    try {
      
        const res = await axios.get(API_URL + 'jobs/data/' + id,
        { 
          headers: AuthService.authHeader()
        });
        //console.log(res)
        return res.data
      
    }
    catch (err) {
      console.log(err.response);
      return err.response
    }
    
  }
  async getJobByJobId(jobid) {
    try {
      
        const res = await axios.get(API_URL + 'job/job-id/' + jobid,
        { 
          headers: AuthService.authHeader()
        });
        //console.log(res)
        return res.data
      
    }
    catch (err) {
      console.log(err.response);
      return err.response
    }
    
  }
  async getEditJob(id) {
    try {
      
        const res = await axios.get(API_URL + 'job/edit/' + id,
        { 
          headers: AuthService.authHeader()
        });
        //console.log(res)
        return res.data
      
    }
    catch (err) {
      console.log(err.response);
      return err.response
    }
    
  }
  async getActiveJobs(user, size, page, status) {
    try {
      
        const res = await axios.get(API_URL + 'jobs/list/' + user + '?size=' + size + '&page=' + page + '&status=' + status,
        { 
          headers: AuthService.authHeader()
        });
        console.log(res)
        return res.data
      
    }
    catch (err) {
      console.log(err.response);
      return err.response
    }
    
  }
  async updateJob(id, data) {
    try {
      const res = await axios.put(API_URL + 'jobs/' + id, data,
      { 
        headers: AuthService.authHeader()
      });
      //console.log(res.data)  
      return res.data;
    }
    catch (err) {
      console.log(err.response);
      return err.response;
    }
  }
  async updateJobStatus(id, data) {
    try {
      const res = await axios.put(API_URL + 'jobs/status/' + id, data,
      { 
        headers: AuthService.authHeader()
      });
      //console.log(res.data)  
      return res.data
    }
    catch (err) {
      console.log(err.response);
      return err.response
    }
  }
  async deleteJob(id) {
    try {
      const res = await axios.delete(API_URL + 'jobs/' + id,
      { 
        headers: AuthService.authHeader()
      });
      //console.log(res.data)  
      return res.data
    }
    catch (err) {
      console.log(err).response;
      return err.response
    }
  }
  // async getJobByStatusAndRecruiter(status, emp_id) {
  //   try {
  //     const res = await axios.get(API_URL + 'jobs/status=' + status + '&recruiter_emp_id=' + emp_id,
  //     { 
  //       headers: AuthService.authHeader()
  //     });
  //     //console.log(res.data)  
  //     return res.data
  //   }
  //   catch (err) {
  //     console.log(err.response);
  //     return err.response
  //   }
  // }
  
  // async getJobsCreatedByUser(id) {
  //   try {
  //     const res = await axios.get(API_URL + 'jobs/user/' + id,
  //     { 
  //       headers: AuthService.authHeader()
  //     });
  //     //console.log(res.data)  
  //     return res.data
  //   }
  //   catch (err) {
  //     console.log(err.response);
  //     return err.response
  //   }
  // }

  async assignCandidateToJob(data) {
    try {
      const res = await axios.patch(API_URL + 'jobs/candidate/',data,
      { 
        headers: AuthService.authHeader()
      });
      console.log(res.data)
      
      return res.data
    }
    catch (err) {
      console.log(err.response);
      return err.response
    }
  }

  async getFile(key) {
    try {
      console.log('key', key)
      const res = await axios.get(API_URL + 'jobs/file/' + key,
      { 
        headers: AuthService.authHeader()
      });
      //console.log(res.data)  
      return res.data;
    }
    catch(err) {
      return err.response;
    }
  }
  async getFileBase64(key) {
    try {
      console.log('key', key)
      const res = await axios.get(API_URL + 'jobs/file/base64/' + key,
      { 
        headers: AuthService.authHeader()
      });
      //console.log(res.data)  
      return res.data;
    }
    catch(err) {
      return err.response;
    }
  }

  async getCandidatesByJob(id) {
    try {
      const res = await axios.get(API_URL + 'jobs/candidates/' + id,
      { 
        headers: AuthService.authHeader()
      });
      return res.data;
    }
    catch(err) {
      //console.log(err.response)
      return err.response;
    }
  }

  async getJobPositionList() {
    try {
      const res = await axios.get(API_URL + 'jobs/position/list',
      { 
        headers: AuthService.authHeader()
      });
      return res.data;
    }
    catch(err) {
      //console.log(err.response)
      return err.response;
    }
  }
  async getJobViewData(id) {
    try {
      const res = await axios.get(API_URL + 'job/view/' + id,
      { 
        headers: AuthService.authHeader()
      });
      return res.data;
    }
    catch(err) {
      //console.log(err.response)
      return err.response;
    }
  }
  async getFilteredJobs(user, client, domain, location, mgr, category, vertical, jobType, payroll, query, status) {
    try {
      
        const res = await axios.get(API_URL + 'jobs/filter/' + user + '?client=' + client + '&domain=' + domain + '&location=' + location + '&mgr=' + mgr + '&category=' + category + '&vertical=' + vertical + '&type=' + jobType + '&payroll=' + payroll  + '&search=' + query + '&status=' + status,
        { 
          headers: AuthService.authHeader()
        });
        //console.log(res)
        return res.data
      
    }
    catch (err) {
      console.log(err.response);
      return err.response
    }
    
  }
  async getJobTracker(id) {
    try {
      const res = await axios.get(API_URL + 'jobs/tracker/' + id,
      { 
        headers: AuthService.authHeader()
      });
      return res;
    }
    catch(err) {
      return err.response;
    }
  }
  
  async getClientTracker(id, startDate, endDate) {
    try {
      const res = await axios.get(API_URL + `jobs/client-tracker/${id}`, {
        params: { startDate, endDate },
        headers: AuthService.authHeader(),
      });
      return res;
    }
    catch(err) {
      return err.response;
    }
  }

  async getCustomDateClientTracker(id, startDate, endDate) {
    try {
      const res = await axios.get(API_URL + `jobs/client-tracker/${id}/custom`, {
        params: { startDate, endDate },
        headers: AuthService.authHeader(),
      });
      return res;
    }
    catch(err) {
      return err.response;
    }
  }
}
export default new JobService();